import { Block, BlockContent, BlockTitle } from '../components/Block'
import { Tab } from '../components/UI/Text'
import { useTitle } from '../hooks/useTitle'

export const InfoPage = () => {
    useTitle({ title: 'Информационная' })
    return (
        <Block>
            <BlockTitle>Информация</BlockTitle>
            <BlockContent>
                <Tab title="FAQ">
                    <Tab title="Рекомендации">
                        На данный момент, рекомендации находятся в стадии
                        "беты". Они работают, но, возможно, не так, как
                        ожидается. <br />
                        При создании нового аккаунта список рекомендаций пуст,
                        потому что он генерируется в 12 часов по МСК ежедневно.
                    </Tab>
                </Tab>
                <Tab title="Пользовательское соглашение">
                    <ol>
                        <li>
                            <p>Общие положения</p>
                            <ol>
                                <li>
                                    <p>
                                        Настоящее пользовательское соглашение
                                        (далее &ndash; Соглашение) регулирует
                                        отношения между владельцем сайта
                                        &quot;animespell.su&quot; (далее &ndash;
                                        Администрация) и лицом (далее &ndash;
                                        Пользователь), использующим сервисы
                                        сайта.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Использование сервисов сайта означает
                                        безоговорочное согласие Пользователя с
                                        настоящим Соглашением. В случае
                                        несогласия с условиями Соглашения,
                                        Пользователь должен прекратить
                                        использование сервисов сайта.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Администрация оставляет за собой право
                                        вносить изменения в Соглашение без
                                        предварительного уведомления. Новая
                                        редакция Соглашения вступает в силу с
                                        момента её размещения на сайте, если
                                        иное не предусмотрено новой редакцией
                                        Соглашения.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Права и обязанности сторон</p>
                            <ol>
                                <li>
                                    <p>Администрация обязуется:</p>
                                    <ol>
                                        <li>
                                            <p>
                                                Своевременно обеспечивать
                                                работоспособность сайта и
                                                предоставляемых на нем сервисов.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Обеспечивать конфиденциальность
                                                персональных данных
                                                Пользователей в соответствии с
                                                действующим законодательством
                                                РФ.
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>Пользователь обязуется:</p>
                                    <ol>
                                        <li>
                                            <p>
                                                Соблюдать условия настоящего
                                                Соглашения.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Не использовать контент сайта в
                                                коммерческих целях без
                                                разрешения Администрации.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Не нарушать авторские права
                                                правообладателей контента,
                                                размещенного на сайте.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Не предпринимать попыток
                                                несанкционированного доступа к
                                                управлению сайтом или его
                                                закрытым разделам.
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Ответственность сторон</p>
                            <ol>
                                <li>
                                    <p>
                                        В случае нарушения Пользователем условий
                                        настоящего Соглашения, Администрация
                                        имеет право ограничить доступ
                                        Пользователя к сервисам сайта или
                                        полностью заблокировать его аккаунт без
                                        возможности восстановления.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        &nbsp;Администрация не несет
                                        ответственности за возможные нарушения
                                        авторских прав третьими лицами,
                                        связанные с размещением контента на
                                        сайте, если такое размещение произошло
                                        без согласия Администрации.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Администрация не несет ответственности
                                        за возможный ущерб, причиненный
                                        Пользователю или третьим лицам в
                                        результате использования сервисов сайта.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Администрация не несет ответственности
                                        за содержание внешних ресурсов, ссылки
                                        на которые могут быть размещены на
                                        сайте.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Пользователь самостоятельно несет
                                        ответственность за все действия,
                                        совершаемые с использованием своего
                                        аккаунта на сайте, включая соблюдение
                                        законодательства РФ и уважение авторских
                                        прав.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Разрешение споров</p>
                            <ol>
                                <li>
                                    <p>
                                        В случае возникновения спорных ситуаций
                                        между Администрацией и Пользователем,
                                        стороны будут стремиться разрешить спор
                                        путем переговоров.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Если спор не может быть разрешен путем
                                        переговоров, он подлежит рассмотрению в
                                        суде по месту нахождения Администрации в
                                        соответствии с действующим
                                        законодательством РФ.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Заключительные положения</p>
                            <ol>
                                <li>
                                    <p>
                                        Настоящее Соглашение составлено в
                                        соответствии с законодательством РФ и
                                        регулирует условия использования
                                        сервисов сайта
                                        &quot;animespell.su&quot;.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Если одно или несколько положений
                                        настоящего Соглашения признаются
                                        недействительными или не подлежат
                                        исполнению, это не влечет
                                        недействительности остальных положений
                                        Соглашения.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Бездействие со стороны Администрации в
                                        случае нарушения Пользователем условий
                                        Соглашения не лишает Администрацию права
                                        предпринять позднейшие действия в защиту
                                        своих интересов и авторских прав на
                                        контент, размещенный на сайте.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Пользователи могут обратиться к
                                        Администрации с вопросами, связанными с
                                        условиями настоящего Соглашения, по
                                        контактным данным, указанным на сайте.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Если Пользователь продолжает
                                        использовать сервисы сайта после
                                        внесения изменений в настоящее
                                        Соглашение, это означает его согласие с
                                        новой редакцией Соглашения.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Во всем остальном, что не предусмотрено
                                        настоящим Соглашением, стороны
                                        руководствуются действующим
                                        законодательством Российской Федерации.
                                    </p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <p>
                        <br />
                    </p>
                    <p>
                        Настоящее пользовательское соглашение вступает в силу с
                        момента его публикации на сайте и действует до момента
                        введения в силу новой редакции Соглашения или до
                        прекращения действия сайта.
                    </p>
                    <br />
                    <b>Заключительные положения</b>
                    <p>
                        <br />
                        Пользователи могут обратиться к Администрации с
                        вопросами, связанными с условиями настоящего Соглашения,
                        по контактным данным, указанным на сайте, или отправить
                        письмо на электронную почту:{' '}
                        <a href="mailto:info@animespell.su">
                            info@animespell.su
                        </a>
                        .
                    </p>
                </Tab>
                <Tab title="Политика конфиденциальности">
                    <ol>
                        <li>
                            <p>Общие положения</p>
                            <ol>
                                <li>
                                    <p>
                                        Настоящая Политика конфиденциальности
                                        (далее &ndash; Политика) определяет
                                        порядок сбора, хранения, использования и
                                        защиты персональных данных пользователей
                                        сайта &quot;animespell.su&quot; (далее
                                        &ndash; Пользователи).
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Использование сервисов сайта означает
                                        безоговорочное согласие Пользователя с
                                        настоящей Политикой. В случае несогласия
                                        с условиями Политики, Пользователь
                                        должен прекратить использование сервисов
                                        сайта.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Администрация оставляет за собой право
                                        вносить изменения в Политику без
                                        предварительного уведомления. Новая
                                        редакция Политики вступает в силу с
                                        момента её размещения на сайте, если
                                        иное не предусмотрено новой редакцией
                                        Политики.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Сбор и использование персональных данных</p>
                            <ol>
                                <li>
                                    <p>
                                        Администрация собирает и хранит только
                                        те персональные данные, которые
                                        необходимы для предоставления услуг и
                                        функционирования сайта, включая:
                                    </p>
                                    <ol>
                                        <li>
                                            <p>электронная почта (email);</p>
                                        </li>
                                        <li>
                                            <p>никнейм (имя пользователя);</p>
                                        </li>
                                        <li>
                                            <p>пароль.</p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>
                                        Администрация может использовать
                                        персональные данные Пользователей для
                                        следующих целей:
                                    </p>
                                    <ol>
                                        <li>
                                            <p>
                                                идентификация и авторизация
                                                Пользователя на сайте;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                предоставление Пользователю
                                                доступа к персонализированным
                                                ресурсам сайта;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                улучшение качества работы сайта,
                                                анализ его использования и
                                                разработка новых функций и
                                                сервисов;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                коммуникация с Пользователем,
                                                включая рассылку уведомлений,
                                                запросов и информации,
                                                касающихся использования сайта.
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Защита персональных данных</p>
                            <ol>
                                <li>
                                    <p>
                                        Администрация принимает все необходимые
                                        меры для защиты персональных данных
                                        Пользователей от неправомерного доступа,
                                        изменения, распространения или удаления.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Доступ к персональным данным
                                        Пользователей имеют только
                                        уполномоченные сотрудники Администрации,
                                        которые обязаны сохранять
                                        конфиденциальность такой информации.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Администрация обязуется не передавать
                                        персональные данные Пользователей
                                        третьим лицам, за исключением следующих
                                        случаев:
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        передача данных требуется по
                                        законодательству Российской Федерации по
                                        запросу компетентных государственных
                                        органов;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        передача данных необходима для защиты
                                        прав и законных интересов Администрации
                                        или третьих лиц.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Использование cookies</p>
                            <ol>
                                <li>
                                    <p>
                                        Администрация использует файлы cookies
                                        для улучшения работы сайта и
                                        предоставления Пользователю
                                        персонализированных сервисов.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Пользователь может запретить
                                        использование файлов cookies в
                                        настройках своего браузера, однако это
                                        может повлиять на функциональность
                                        сайта.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Ответственность сторон</p>
                            <ol>
                                <li>
                                    <p>
                                        При потере или разглашении персональных
                                        данных Администрация не несет
                                        ответственности, если эти данные стали
                                        доступными третьим лицам не в результате
                                        нарушения Администрацией условий
                                        настоящей Политики.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Пользователь самостоятельно несет
                                        ответственность за соблюдение мер
                                        безопасности своей учетной записи,
                                        включая сохранение пароля в тайне и
                                        использование надежных методов
                                        авторизации.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Заключительные положения</p>
                            <ol>
                                <li>
                                    <p>
                                        Настоящая Политика составлена в
                                        соответствии с законодательством
                                        Российской Федерации и регулирует
                                        порядок обработки персональных данных на
                                        сайте &quot;animespell.su&quot;.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Во всем остальном, что не предусмотрено
                                        настоящей Политикой, стороны
                                        руководствуются действующим
                                        законодательством Российской Федерации.
                                    </p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </Tab>
                <Tab title="Уведомление об авторском праве и удалении контента">
                    <ol>
                        <li>
                            <p>Общие положения</p>
                            <ol>
                                <li>
                                    <p>
                                        Настоящее Уведомление об авторском праве
                                        (далее &ndash; Уведомление) определяет
                                        порядок рассмотрения и удаления контента
                                        с сайта &quot;animespell.su&quot; (далее
                                        &ndash; Сайт) в случае нарушения
                                        авторских прав.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Администрация Сайта уважает и признает
                                        авторские права владельцев контента и
                                        стремится соблюдать все требования
                                        законодательства РФ, касающиеся защиты
                                        авторских прав.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Уведомление о нарушении авторских прав</p>
                            <ol>
                                <li>
                                    <p>
                                        В случае обнаружения на Сайте контента,
                                        нарушающего авторские права, владелец
                                        авторских прав или его представитель
                                        может направить Администрации письменное
                                        уведомление с указанием следующей
                                        информации:
                                    </p>
                                    <ol>
                                        <li>
                                            <p>
                                                подтверждение того, что
                                                заявитель является владельцем
                                                авторских прав или имеет
                                                соответствующее полномочие от
                                                владельца;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                полное наименование и описание
                                                нарушенного произведения,
                                                защищенного авторским правом;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                точная ссылка на страницу Сайта,
                                                где расположен контент,
                                                нарушающий авторские права;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                контактные данные заявителя,
                                                включая имя, адрес, телефон и
                                                адрес электронной почты;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                заявление о том, что заявитель
                                                действует в добросовестной
                                                убежденности, что использование
                                                контента на Сайте не разрешено
                                                владельцем авторских прав, его
                                                агентом или законом;
                                            </p>
                                        </li>
                                        <li>
                                            <p>подпись заявителя.</p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>
                                        Уведомление о нарушении авторских прав
                                        должно быть направлено на электронную
                                        почту{' '}
                                        <a href="mailto:info@animespell.su">
                                            info@animespell.su
                                        </a>
                                        .
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Рассмотрение уведомления и удаление контента</p>
                            <ol>
                                <li>
                                    <p>
                                        Администрация обязуется рассмотреть
                                        уведомление о нарушении авторских прав в
                                        течение 5 (пяти) рабочих дней с даты его
                                        получения и предпринять соответствующие
                                        действия для удаления нарушающего
                                        контента с Сайта.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        В случае достаточных оснований для
                                        признания нарушения авторских прав,
                                        Администрация обязуется удалить или
                                        заблокировать доступ к нарушающему
                                        контенту в кратчайшие сроки.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Если после удаления контента
                                        Администрация получит достоверное
                                        подтверждение о его законности, она
                                        может восстановить доступ к контенту на
                                        свое усмотрение.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Ответственность сторон</p>
                            <ol>
                                <li>
                                    <p>
                                        Администрация не несет ответственности
                                        за размещение на Сайте контента,
                                        нарушающего авторские права, если это
                                        произошло без ее ведома или согласия.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Заявитель несет ответственность за
                                        достоверность представленной информации
                                        и правомерность предъявления претензий в
                                        отношении авторских прав.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        В случае предоставления заведомо ложной
                                        информации о нарушении авторских прав,
                                        заявитель может нести ответственность в
                                        соответствии с действующим
                                        законодательством Российской Федерации.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Заключительные положения</p>
                            <ol>
                                <li>
                                    <p>
                                        Настоящее Уведомление составлено в
                                        соответствии с законодательством
                                        Российской Федерации и регулирует
                                        порядок рассмотрения претензий и
                                        удаления контента с Сайта в случае
                                        нарушения авторских прав.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Во всем остальном, что не предусмотрено
                                        настоящим Уведомлением, стороны
                                        руководствуются действующим
                                        законодательством Российской Федерации.
                                    </p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </Tab>
            </BlockContent>
        </Block>
    )
}
